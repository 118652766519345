import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class CostCenterService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiservice: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  getCostList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    accountID?: string,
    centerName?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/cost-centre/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountID) {
      path += `&accountID=${accountID}`;
    }
    if (centerName) {
      path += `&centerName=${centerName}`;
    }

    return this.apiservice.get(path);
  }

  getCostListCustomer(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    accountID?: string,
    centerName?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/customer/cost-center/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountID) {
      path += `&accountID=${accountID}`;
    }
    if (centerName) {
      path += `&centerName=${centerName}`;
    }

    return this.apiservice.get(path);
  }

  getCostListById(id: string): Observable<any> {
    const path = `${this.baseUrl}/cost-centre/${id}`;
    return this.apiservice.get(path);
  }

  getCostListByAccountId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    accountId: string
  ): Observable<any> {
    const path = `${this.baseUrl}/cost-centre/list?accountID=${accountId}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }

  createCost(formData: any): Observable<any> {
    let path = `${this.baseUrl}/cost-centre`;
    return this.apiservice.post(path, formData);
  }

  updateCost(costCentreData: any): Observable<any> {
    const url = `${this.baseUrl}/cost-centre`;
    return this.apiservice.put(url, costCentreData).pipe(
      catchError((error: any) => {
        console.error('Error updating costCentre:', error);
        throw error;
      })
    );
  }

  deleteCost(id: string) {
    const path = `${this.baseUrl}/cost-centre/${id}`;
    return this.apiservice.delete(path);
  }

  getActivityLogs(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: '' | 'ASC' | 'DESC' = ''
  ): Observable<any> {
    const path = `${this.baseUrl}/auth/activity-logs?module=cost_center&module=project&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }
}
